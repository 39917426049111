<template>
  <section>
    <div class="evaluation">
      <div class="evaluation_wrap">
        <div class="content">
          <p class="abstract">
            “我性格内向/外向，适合什么工作？”“哪些职业正好匹配我的性格？”“以我的个性从事什么行业好？”“我性格中的优势和劣势是什么？”“我是不是该继续现在从事的职业？”
            不论是正待走进职场的毕业生，还是工作了一段时间的人，面对这类问题都会感到困惑——性格因素和职业选择之间到底存在什么样的关联呢？
          </p>
          <div class="first">
            <div class="text">
              <h2>在线职业能力测评</h2>
              <p>在线测评为您提供了丰富的个性化测评设置空间，专业的功能</p>
              <p>设置直观展现差异化的测评结果，从而搭建专属您的测评系统。</p>
              <div>
                <el-button type="primary" @click="toTest">开始测评</el-button>
              </div>
            </div>
            <img src="~/static/shanxi/evalu03.jpg" />
          </div>
          <div class="second" v-show="location != '甘肃省'">
            <img src="~/static/shanxi/evalu02.jpg" />
            <div class="text">
              <h2>机构面评</h2>
              <p>在线测评为您提供了丰富的个性化测评设置空间，专业的功能</p>
              <p>设置直观展现差异化的测评结果，从而搭建专属您的测评系统。</p>
              <div>
                <el-button type="primary" @click="open()">立即预约</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="机构面评预约" :visible.sync="dialogVisible" width="600px">
      <div class="clearfix">
        <el-form ref="form" :model="form" label-width="80px" :rules="err">
          <el-form-item label="手机号码" class="pofixere" prop="mobile">
            <el-input
              v-model="form.mobile"
              class="fl"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="测评时间" prop="appointDate">
            <el-date-picker
              type="datetime"
              style="width: 300px"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="请选择预约时间(只能从明天开始选)"
              v-model="form.appointDate"
            >
            </el-date-picker>
          </el-form-item>
          <div class="text_C">
            <el-button type="primary" class="underlinebtn" @click="applyTest"
              >确定预约</el-button
            >
            <el-button
              plain
              class="jobdetailbtn h46"
              @click="dialogVisible = false"
              >取消</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
    <login-prompt
      :goShow="isShow"
      @close="handleClose"
      title="职位申请"
    ></login-prompt>
  </section>
</template>

<script>
import LoginPrompt from "components/public/loginPrompt.vue";
export default {
  metaInfo() {
    return {
      meta: this.meta,
    };
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          // 设置可选择的日期为今天之后的一个月内
          const curDate = new Date().getTime();
          // 这里算出一个月的毫秒数,
          //                    const day = 10 * 24 * 3600 * 1000
          //                    const dateRegion = curDate + day
          return time.getTime() < Date.now() + 24 * 3600 * 1000 - 8.64e7;
        },
      },
      err: {
        mobile: [
          {
            required: true,
            message: "请输入您的手机号码",
            trrigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        appointDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
      },
      dialogVisible: false,
      type: "",
      form: {
        mobile: "",
        appointDate: "",
      },
      isLogin: "",
      isShow: false,
      meta:[],
      location:'',
    };
  },
  components: { LoginPrompt },
  methods: {
    handleClose() {
      this.isShow = false;
    },
    open() {
      if (this.isLogin) {
        if (this.isLogin.userType == "1") {
          this.dialogVisible = true;
        } else if (this.isLogin.userType == "2" || "4") {
          this.$message.error("您不是残疾人，不能预约机构面评");
        }
      } else {
        this.isShow = true;
      }
    },
    toTest() {
      if (this.isLogin) {
        if (this.isLogin.userType == "1") {
          window.open(
            "https://cjrcp.webtrn.cn/zcl_zycs/zcl/general/homePage/homePage.html"
          );
        } else if (this.isLogin.userType == "2" || "4") {
          this.$message.error("您不是残疾人，不能进行在线职业能力测评");
        } else {
          this.isShow = true;
        }
      } else {
        this.isShow = true;
      }
    },
    applyTest() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = await this.$axios.post(
            "/api/app-jycy-henan/henan/psycho/appoint",
            {
              mobile: this.form.mobile,
              appointDate: this.form.appointDate,
            }
          );
          if (!res.data.success) {
            this.$message.warning(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
          }
          this.isShow = false;
        }
      });
    },
    getLocation() {
       if (this.location == '陕西省') {
        document.title = '能力测评-陕西省残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '陕西残疾人能力测评，残疾人能力测评,陕西省残疾人测评,陕西省残联,',
            },
            {
              name: "description",
              content: '陕西省残疾人能力测评为残疾人提供能力测评服务。'
            }
        );
      } else if (this.location == '甘肃省') {
        document.title = '能力测评-兰州市残疾人就业创业网络服务平台';
        this.meta.push(
            {
              name: "keywords",
              content: '兰州残疾人能力测评，残疾人能力测评,兰州市残疾人测评,兰州市残联',
            },
            {
              name: "description",
              content: '兰州市残疾人能力测评为残疾人提供能力测评服务。'
            }
        );
      }
    }
  },
  created() {
    this.isLogin = JSON.parse(localStorage.getItem("userType")) || "";
    this.location = localStorage.getItem("locationSite")||"";
    this.getLocation();
  },
};
</script>
<style scoped lang="less">
.evaluation {
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  background: #fff;
  .evaluation_wrap {
    padding: 35px 100px 45px;
    .content {
      .abstract {
        line-height: 32px;
        font-size: 18px;
        text-indent: 2em;
      }
      .first {
        padding-top: 45px;
        display: flex;
      }
      .text {
        flex: 1;
        h2 {
          font-size: 30px;
          font-weight: 400;
          line-height: 27px;
        }
        p {
          font-size: 18px;
          margin-top: 18px;
          &:nth-child(2n) {
            margin-top: 30px;
          }
        }
        div {
          button {
            margin-top: 30px;
            font-size: 16px;
            padding: 0 20px;
            line-height: 48px;
            color: #fff;
            border-radius: 5px;
          }
        }
      }
      img {
        width: 460px;
        height: 281px;
      }
      .second {
        display: flex;
        margin-top: 65px;
        .text {
          margin-left: 60px;
        }
      }
    }
  }
}
</style>
